import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { GetChat, SeletVariation, AnsToQue } from "../services/ChatService";
import { v4 as uuidv4 } from 'uuid';

// import Countdown from "react-countdown";
import io from "socket.io-client";
// import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
const socket = io.connect(process.env.REACT_APP_BACKEND_URI, { autoConnect: true, reconnect: true, reconnectionAttempts: "Infinity", transports: ['websocket', 'pollings'] });
socket.on('connect', function () {
    console.log("SUCCESS CONNECTION");
});
const ChatWidget = () => {
    const [ChatInfo, setChatInfo] = useState(undefined);
    const [WaitResponse, SetWaitResponse] = useState(false);
    const search = useLocation().search;
    const shop = new URLSearchParams(search).get("shop_id");
    const product_id = new URLSearchParams(search).get("product_id");
    const campaign_id = new URLSearchParams(search).get("campaign_id");

    const [lastChat, SetLastChat] = useState({});
    const ans = useRef(null);
    const bottomRef = useRef(null);
    const [CharacterCount, setCharacterCount] = useState(0);
    const [EmailErrorMessage, setEmailErrorMessage] = useState(null);
    const [room, setRoom] = useState("");
    const exceptThisSymbols = ["e", "E", "+", "-"];
    const [LiveChatStartFlag, setLiveChatStartFlag] = useState(false);



    useEffect(() => {
        if (shop !== undefined && campaign_id !== undefined) {
            getChatINfo();
        }
    }, []);

    const getChatINfo = async () => {
        let unique_token_user = await commonUserToken();
        let dynamicParameter = {
            product_id: product_id,
            shop: shop,
            campaign_id: campaign_id,
            unique_token_user: unique_token_user,
        };
        if (product_id === undefined || product_id === null) {
            dynamicParameter = {
                shop: shop,
                campaign_id: campaign_id,
                unique_token_user: unique_token_user,
            }
        }
        await GetChat(dynamicParameter).then((res) => {
            const pro_data = res.data;
            if (pro_data.header.chatuuid !== undefined) {
                localStorage.setItem('chatuuid', pro_data.header.chatuuid);
                setRoom(pro_data.header.chatuuid);
                joinRoom(pro_data.header.chatuuid);
            }
            const chat_detail = pro_data.detail;
            const chat_length = chat_detail.length;
            setCharacterCount(0);
            if (chat_length - 1 >= 0) {
                SetLastChat(chat_detail[chat_length - 1]);
            }
            SetWaitResponse(false);
            setChatInfo(pro_data);
            setTimeout(function () {
                bottomRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 200);
        });
    };

    const SelectVariation = async (variations, id, bargin_header_id) => {
        SetWaitResponse(true);
        await SeletVariation({
            selectedVariantId: variations.id,
            prduct_variant_name: variations.title,
            product_price: variations.price,
            product_compare_price: variations.compareAtPrice,
            bargin_header_id: bargin_header_id,
            ans_value: variations.id,
            ans: variations.title,
            id: id,
        }).then((res) => {
            sendMessage(variations.title);
            getChatINfo();
        });
    };

    const AnsToQuetion = async (header_id, change_mind_flag = "no") => {
        if (ChatInfo.header.action === "email") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(ans.current.value)) {
                setEmailErrorMessage('Please enter valid email address');
                SetWaitResponse(false);

                return;
            } else {
                setEmailErrorMessage(null);
            }
        }
        let objectData = {};
        if (change_mind_flag === 'yes') {
            objectData = {
                bargin_header_id: lastChat.bargin_header_id,
                id: lastChat.id,
                campaign_id: campaign_id,
                change_mind_flag: change_mind_flag,
                chatuuid: room,
            }
        } else {
            objectData = {
                bargin_header_id: lastChat.bargin_header_id,
                ans_value: ans.current.value,
                ans: ans.current.value,
                id: lastChat.id,
                campaign_id: campaign_id,
                change_mind_flag: change_mind_flag,
                chatuuid: room
            }

        }
        if (ChatInfo.header.livechat === 1) {
            sendMessage(objectData);
            setChatInfo({
                ...ChatInfo,
                detail: [...ChatInfo.detail, objectData]
            });
            ans.current.value = null;
        } else {
            if (ans.current.value !== null && ans.current.value !== '' && !WaitResponse) {
                await AnsToQue(objectData).then((res) => {
                    if (change_mind_flag === 'no') {
                        sendMessage(ans.current.value);
                        ans.current.value = null;
                    }
                    getChatINfo();
                });
            }

        }
    };

    const AcceptOrRefuse = async (answer) => {
        SetWaitResponse(true);
        await AnsToQue({
            bargin_header_id: lastChat.bargin_header_id,
            ans_value: answer,
            ans: answer,
            id: lastChat.id,
            campaign_id: campaign_id,
            change_mind_flag: "no",
            chatuuid: room
        }).then((res) => {
            sendMessage(answer);
            getChatINfo();
        });
    };

    const commonUserToken = async () => {
        let unique_token_user = localStorage.getItem('unique_token_user');
        if (unique_token_user == undefined) {
            const uniqueTokenUser = uuidv4();

            // const res = await axios.get('https://geolocation-db.com/json/');
            // unique_token_user = res.data.IPv4;
            // const unique_tok_en_user = uuidv4();
            localStorage.setItem('unique_token_user', uniqueTokenUser);
        }
        return unique_token_user;
    }

    const recalculate = e => {
        let textAreaCount = e.target.value.length;
        setCharacterCount(e.target.value.length);
    };

    // Random component
    const Completionist = () => <span>Your Offer has expired</span>;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <span>
                    Offer expires in {hours}:{minutes}:{seconds}
                </span>
            );
        }
    };

    const sendMessageToParent = (event) => {
        window.parent.postMessage(event, "*");
    };

    async function joinRoom() {
        let token = localStorage.getItem('chatuuid');
        if (token !== "") {
            setRoom(token);
            socket.emit("join_room", token);
        } else {
            setRoom(token);
        }
    };

    const sendMessage = async (value) => {
        if (value !== "") {
            const messageData = {
                type: 'question',
                room: room,
                livechat: ChatInfo.header.livechat,
                message: value,
                time:
                    new Date(Date.now()).getHours() +
                    ":" +
                    new Date(Date.now()).getMinutes(),
            };
            await socket.emit("send_message", messageData);
        }
    };


    const liveChat = async () => {
        if (ans.current.value !== null && ans.current.value !== '') {
            let objectData = {
                bargin_header_id: lastChat.bargin_header_id,
                ans_value: ans.current.value,
                ans: ans.current.value,
                id: lastChat.id,
                campaign_id: campaign_id,
                change_mind_flag: 'no',
                chatuuid: room,
                que: null,
                que_value: null
            };
            sendMessage(objectData);
            setChatInfo({
                ...ChatInfo,
                detail: [...ChatInfo.detail, objectData]
            });
            setTimeout(function () {
                bottomRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 200);
            ans.current.value = null;
        }
    };

    useEffect(() => {
        socket.on("receive_message", (data) => {
            const pro_data = data.message;
            if (pro_data.header.chatuuid !== undefined) {
                localStorage.setItem('chatuuid', pro_data.header.chatuuid);
                setRoom(pro_data.header.chatuuid);
                joinRoom(pro_data.header.chatuuid);
            }
            const chat_detail = pro_data.detail;
            const chat_length = chat_detail.length;
            setCharacterCount(0);
            if (chat_length - 1 >= 0) {
                SetLastChat(chat_detail[chat_length - 1]);
            }
            setChatInfo(pro_data);
            document.getElementById("my_audio").play();
            setTimeout(function () {
                bottomRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 200);
        });
    });


    const CountExpireCodeTimer = () => {
        // Set the date we're counting down to
        var countDownDate = new Date(ChatInfo.header.discount_code_expire_timer).getTime();
        // Update the count down every 1 second
        var x = setInterval(function () {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            days = days === 0 ? '' : days + " Days ";
            hours = hours === 0 ? '00:' : (hours < 10) ? ("0" + hours) + ':' : hours + ':';
            minutes = minutes === 0 ? '00:' : (minutes < 10) ? ("0" + minutes) + ':' : minutes + ':';
            seconds = seconds === 0 ? '00' : (seconds < 10) ? ("0" + seconds) : seconds;

            // Display the result in the element with id="demo"
            document.getElementById("demo").innerHTML = 'Offer expires in ' + days + hours + minutes + seconds;

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                document.getElementById("demo").innerHTML = "Your Offer has expired";
            }
        }, 1000);
    }

    const connectWithLiveChat = () => {
        setLiveChatStartFlag(true);
    }

    return (
        <React.Fragment>
            {ChatInfo !== undefined && (
                <div id="chat_container" className="sc-chat-window active">
                    <div className="sc-header">
                        <img
                            src="https://barginator--s3.s3.us-east-2.amazonaws.com/Vector.svg"
                            alt=""
                            className="sc-header--img"
                        />
                        <div className="sc-header--team-name">
                            <p className="">{ChatInfo.header.product_name}</p>
                            {ChatInfo.header.prduct_variant_name !== null && (
                                <>
                                    <br />
                                    {ChatInfo.header.prduct_variant_name} : {ChatInfo.header.product_price !== null ? ChatInfo.header.currency_symbol : ''}
                                    {ChatInfo.header.product_price}
                                </>
                            )}
                            {ChatInfo.header.prduct_variant_name === null && (
                                <>
                                    <br />{ChatInfo.header.product_price !== null ? ChatInfo.header.currency_symbol : ''}
                                    {ChatInfo.header.product_price !== null ? ChatInfo.header.product_price : ''}
                                </>
                            )}
                        </div>
                        <div
                            className="sc-header--close-button"
                            onClick={() => {
                                window.parent.postMessage("CallFunctionA", "*");
                            }}
                        >
                            <img
                                src="https://barginator--s3.s3.us-east-2.amazonaws.com/minimize.png"
                                alt="Minimize Chat"
                            />
                        </div>
                    </div>
                    <div id="chat_message_content" className="sc-message-list">
                        {ChatInfo.detail !== undefined &&
                            ChatInfo.detail.length > 0 &&
                            ChatInfo.detail.map((row, index) => {
                                return (
                                    <div key={`parent_loop_${index}`}>
                                        {row.step !== 18 && row.que !== null && (
                                            <div
                                                className="sc-message"
                                                key={`loop_${row.id}`}
                                            >
                                                <div className="sc-message--content received">
                                                    <div className="sc-message--avatar sc-message--avatar-bot"></div>
                                                    {row.que.startsWith('http://') || row.que.startsWith('https://') ?
                                                        <div className="sc-message--text">
                                                            <a href={row.que} target="_blank">{row.que}</a>
                                                        </div>
                                                        :
                                                        <div className="sc-message--text">
                                                            {row.que}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {row.step === 21 && (
                                            <div
                                                className="countdown"
                                                style={{
                                                    textAlign: "center",
                                                    paddingTop: "15px",
                                                }}
                                            >
                                                <p id="demo"><CountExpireCodeTimer /></p>
                                            </div>
                                        )}

                                        {row.step === 1 &&
                                            row.que !== null &&
                                            row.ans === null &&
                                            ChatInfo.header.chat_type === 'bargain_discount' && (
                                                <div
                                                    className="sc-message"
                                                    key={`que_loop_${index}`}
                                                >
                                                    <div className="sc-message--content received">
                                                        <div className="sc-message--avatar sc-message--avatar-bot"></div>{" "}
                                                        <div className="sc-message--text">
                                                            {JSON.parse(
                                                                row.que_value
                                                            ).map(
                                                                (
                                                                    variations,
                                                                    index_variation
                                                                ) => {
                                                                    return (
                                                                        <button
                                                                            type="button"
                                                                            className="sc-message--btn-variant"
                                                                            key={`button_key_${index_variation}`}
                                                                            disabled={WaitResponse}
                                                                            onClick={() =>
                                                                                SelectVariation(
                                                                                    variations.node,
                                                                                    row.id,
                                                                                    row.bargin_header_id
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                variations
                                                                                    .node
                                                                                    .title
                                                                            }
                                                                        </button>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        {row.ans !== null && (
                                            <div className="sc-message">
                                                <div className="sc-message--content sent">
                                                    <div className="sc-message--avatar"></div>{" "}
                                                    <div className="sc-message--text">
                                                        {row.ans}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        <div ref={bottomRef} />
                    </div>
                    {ChatInfo.header.progression !== 'FINISH' && (
                        <>
                            {ChatInfo.header.livechat === 0 && (
                                <>
                                    {ChatInfo.header.action === "number" && (
                                        <div className="sc-user-input">
                                            <input
                                                id="quantity_input"
                                                min="1"
                                                pattern="[0-9]*"
                                                type="number"
                                                className="quantity_input"
                                                ref={ans}
                                                placeholder="Write Numbers Only"
                                                onChange={recalculate}
                                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter" && CharacterCount !== 0 && !WaitResponse && ans.current.value !== '') {
                                                        SetWaitResponse(true);
                                                        AnsToQuetion(ChatInfo.header.id)
                                                    }
                                                }}
                                            />
                                            <div className="sc-user-input--buttons">
                                                <div className="sc-user-input--button">
                                                    <button
                                                        type="button"
                                                        className="sc-user-input--send-icon-wrapper"
                                                        disabled={CharacterCount === 0 && WaitResponse}
                                                        onClick={() => {
                                                            SetWaitResponse(true);
                                                            AnsToQuetion(ChatInfo.header.id)
                                                        }
                                                        }
                                                    >

                                                        <svg
                                                            version="1.1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x="0px"
                                                            y="0px"
                                                            width="37.393px"
                                                            height="37.393px"
                                                            viewBox="0 0 37.393 37.393"
                                                            enableBackground="new 0 0 37.393 37.393"
                                                            className="sc-user-input--send-icon"
                                                        >
                                                            <g id="Layer_2">
                                                                <path d="M36.511,17.594L2.371,2.932c-0.374-0.161-0.81-0.079-1.1,0.21C0.982,3.43,0.896,3.865,1.055,4.241l5.613,13.263 L2.082,32.295c-0.115,0.372-0.004,0.777,0.285,1.038c0.188,0.169,0.427,0.258,0.67,0.258c0.132,0,0.266-0.026,0.392-0.08 l33.079-14.078c0.368-0.157,0.607-0.519,0.608-0.919S36.879,17.752,36.511,17.594z M4.632,30.825L8.469,18.45h8.061 c0.552,0,1-0.448,1-1s-0.448-1-1-1H8.395L3.866,5.751l29.706,12.757L4.632,30.825z"></path>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {ChatInfo.header.action === "choose" && (
                                        <div className="sc-user-input">
                                            <button
                                                type="button"
                                                className="sc-user--btn sc-user--btn-yes"
                                                disabled={WaitResponse}
                                                onClick={() => AcceptOrRefuse("Accept")}
                                            >
                                                Accept
                                            </button>{" "}
                                            <button
                                                type="button"
                                                className="sc-user--btn sc-user--btn-no"
                                                disabled={WaitResponse}
                                                onClick={() => AcceptOrRefuse("Refuse")}
                                            >
                                                Refuse
                                            </button>
                                        </div>
                                    )}
                                    {ChatInfo.header.action === "email" && (
                                        <><div className="sc-user-input">
                                            <input
                                                id="message_text_box"
                                                role="button"
                                                tabIndex="0"
                                                contentEditable="true"
                                                placeholder="Write a reply..."
                                                className="quantity_input"
                                                ref={ans}
                                                onChange={recalculate}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter" && CharacterCount !== 0 && !WaitResponse && ans.current.value !== '') {
                                                        SetWaitResponse(true);
                                                        AnsToQuetion(ChatInfo.header.id)
                                                    }
                                                }} />
                                            <div className="sc-user-input--buttons">
                                                <div className="sc-user-input--button">
                                                    <button
                                                        type="button"
                                                        className="sc-user-input--send-icon-wrapper"
                                                        disabled={CharacterCount === 0 && WaitResponse}
                                                        onClick={() => {
                                                            SetWaitResponse(true);
                                                            AnsToQuetion(ChatInfo.header.id);
                                                        }}
                                                    >
                                                        <svg
                                                            version="1.1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x="0px"
                                                            y="0px"
                                                            width="37.393px"
                                                            height="37.393px"
                                                            viewBox="0 0 37.393 37.393"
                                                            enableBackground="new 0 0 37.393 37.393"
                                                            className="sc-user-input--send-icon"
                                                        >
                                                            <g id="Layer_2">
                                                                <path d="M36.511,17.594L2.371,2.932c-0.374-0.161-0.81-0.079-1.1,0.21C0.982,3.43,0.896,3.865,1.055,4.241l5.613,13.263 L2.082,32.295c-0.115,0.372-0.004,0.777,0.285,1.038c0.188,0.169,0.427,0.258,0.67,0.258c0.132,0,0.266-0.026,0.392-0.08 l33.079-14.078c0.368-0.157,0.607-0.519,0.608-0.919S36.879,17.752,36.511,17.594z M4.632,30.825L8.469,18.45h8.061 c0.552,0,1-0.448,1-1s-0.448-1-1-1H8.395L3.866,5.751l29.706,12.757L4.632,30.825z"></path>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>{EmailErrorMessage !== null && (
                                            <h3 style={{ color: "red", textAlign: "center", fontSize: "11px" }}>{EmailErrorMessage}</h3>
                                        )}</>
                                    )}
                                    {ChatInfo.header.action === "close" && (
                                        <div className="sc-user-input">
                                            <span className="close_msg">
                                                You can close the chat
                                            </span>
                                        </div>
                                    )}
                                    {ChatInfo.header.action === "change" && (
                                        <a onClick={() =>
                                            AnsToQuetion(ChatInfo.header.id, "yes")} style={{ cursor: "pointer" }}><div
                                                className="sc-user-input">
                                                <span className="close_msg">
                                                    I changed my mind
                                                </span>
                                            </div></a>
                                    )}

                                </>

                            )}
                            {ChatInfo.header.livechat === 1 && LiveChatStartFlag && (
                                <>
                                    <div className="sc-user-input">
                                        <input
                                            id="live_chat_button"
                                            type="text"
                                            className="quantity_input"
                                            ref={ans}
                                            onChange={recalculate}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter" && CharacterCount !== 0) {
                                                    liveChat()
                                                }
                                            }}
                                        />
                                        <div className="sc-user-input--buttons">
                                            <div className="sc-user-input--button">
                                                <button
                                                    type="button"
                                                    className="sc-user-input--send-icon-wrapper"
                                                    disabled={CharacterCount === 0}
                                                    onClick={() =>
                                                        liveChat()
                                                    }
                                                >

                                                    <svg
                                                        version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        x="0px"
                                                        y="0px"
                                                        width="37.393px"
                                                        height="37.393px"
                                                        viewBox="0 0 37.393 37.393"
                                                        enableBackground="new 0 0 37.393 37.393"
                                                        className="sc-user-input--send-icon"
                                                    >
                                                        <g id="Layer_2">
                                                            <path d="M36.511,17.594L2.371,2.932c-0.374-0.161-0.81-0.079-1.1,0.21C0.982,3.43,0.896,3.865,1.055,4.241l5.613,13.263 L2.082,32.295c-0.115,0.372-0.004,0.777,0.285,1.038c0.188,0.169,0.427,0.258,0.67,0.258c0.132,0,0.266-0.026,0.392-0.08 l33.079-14.078c0.368-0.157,0.607-0.519,0.608-0.919S36.879,17.752,36.511,17.594z M4.632,30.825L8.469,18.45h8.061 c0.552,0,1-0.448,1-1s-0.448-1-1-1H8.395L3.866,5.751l29.706,12.757L4.632,30.825z"></path>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </>
                            )}

                            {ChatInfo.header.livechat === 1 && !LiveChatStartFlag && (
                                <div className="sc-user-input">
                                    <button
                                        type="button"
                                        className="sc-user--btn-add-to-cart"
                                        onClick={connectWithLiveChat
                                        }
                                    >
                                        Connect With Live Chat
                                    </button>
                                </div>
                            )}
                        </>
                    )}

                    {ChatInfo.header.action === "cart" && ChatInfo.header.progression === "FINISH" && (
                        <div className="sc-user-input">
                            <button
                                type="button"
                                className="sc-user--btn-add-to-cart"
                                onClick={() =>
                                    sendMessageToParent({
                                        event: "add_to_cart",
                                        variantId:
                                            ChatInfo.header.selectedVariantId.match(
                                                /\d+/
                                            ),
                                        quantity: ChatInfo.header.quantity,
                                        discountCode:
                                            ChatInfo.header.discountcode,
                                    })
                                }
                            >
                                ADD TO CART
                            </button>
                        </div>
                    )}

                    {ChatInfo.header.progression === 'FINISH' && ChatInfo.header.action !== "cart" && (
                        <div className="sc-user-input">
                            <span className="close_msg">
                                Your chat has been closed by admin.
                            </span>

                        </div>
                    )}
                    <audio src="https://barginator--s3.s3.us-east-2.amazonaws.com/ringtone.mp3" id="my_audio"></audio>
                </div>
            )}
        </React.Fragment>
    );
};

export default ChatWidget;
