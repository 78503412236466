import React from "react";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import ChatWidget from "./components/ChatWidget";
import "./assets/css/style.css";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<ChatWidget />} path="/" exact={true} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
