import { api, handleResponse, handleError } from "./apiServices";

export const GetChat = (data) =>
    api()
        .post("/api/store_front/get_the_chat", data)
        .then(handleResponse)
        .catch(handleError);
export const SeletVariation = (data) =>
    api()
        .post("/api/store_front/select_variation", data)
        .then(handleResponse)
        .catch(handleError);
export const AnsToQue = (data) =>
    api()
        .post("/api/store_front/ans_to_que", data)
        .then(handleResponse)
        .catch(handleError);
